<template>
  <div>
    <b-card :title="titulo">
      <b-card-text>
        <validation-observer ref="form" v-slot="{ reset }">
          <b-form @reset.prevent="reset">
            <b-row>
              <b-col cols="12" md="4" xl="3">
                <b-form-group
                  label="Rango de Fechas:"
                  label-for="rangoFechas"
                  label-cols-lg="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Rango de Fechas"
                    rules="required"
                    vid="rangoFechas"
                  >
                    <flat-pickr
                      id="rangoFechas"
                      v-model="rangoFechas"
                      class="form-control form-control-sm-small"
                      :config="flatPickrConfig"
                      @on-change="onChange"
                      @on-close="onClose"
                      placeholder="Seleecione un rango de fechas"
                    />
                    <small class="text-danger text-small">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4" xl="4">
                <b-form-group
                  label="Cliente o Proveedor:"
                  label-for="Cliente o Proveedor"
                  label-cols="12"
                  label-cols-lg="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cliente o Proveedor"
                    rules="required"
                  >
                    <v-select
                      id="Cliente o Proveedor"
                      v-model="form.clienteProveedorId"
                      label="numero_documento_razon_social"
                      class="style-chooser select-size-sm"
                      placeholder="Buscar Por Documento o Nombre"
                      :filterable="false"
                      :clearable="false"
                      :options="clientesProveedores"
                      :key="form.clienteProveedorId"
                      :reduce="(opcion) => opcion.id"
                      @search="buscarClientesProveedores"
                    >
                      <template #no-options="{ search }">
                        <span v-if="search">
                          Lo siento, no hay opciones de coincidencia
                        </span>
                        <span v-else>Digite para buscar</span>
                      </template>
                    </v-select>
                    <small class="text-danger text-small">
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="4"
                xl="5"
                class="mt-1 mt-md-0 pt-personalizado"
              >
                <b-button
                  type="reset"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  @click="restablecer"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="RotateCwIcon" class="mr-50" />
                    <span class="align-middle">RESTABLECER</span>
                  </span>
                  <feather-icon
                    icon="RotateCwIcon"
                    class="d-md-none"
                    size="14"
                  />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  class="ml-1"
                  @click="validar(false)"
                >
                  <feather-icon icon="SearchIcon" class="mr-50" />
                  <span class="align-middle">CONSULTAR EN PANTALLA</span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="ml-1"
                  @click="validar(true)"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span class="align-middle">VISUALIZAR EN PDF</span>
                  </span>
                  <feather-icon icon="FileIcon" class="d-md-none" size="14" />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>

      <b-card-text>
        <ListadoTransaccionesDetalladas
          ref="listado"
          :items="items"
          :loading="loading"
          :tipoTransaccion="tipoTransaccion"
        />
      </b-card-text>
    </b-card>

    <ModalMostrarPdf
      :titulo="`${titulo} del ${this.form.desde} al ${this.form.hasta}`"
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :mostrarModal="mostarModal"
      @cerrarModal="cerrarModal"
    />
  </div>
</template>

<script>
import ReportesMixin from "../mixins/ReportesMixin";
import ListadoTransaccionesDetalladas from "../views/ListadoTransaccionesDetalladas.vue";
import { formatearFecha } from "../utils";
import vSelect from "vue-select";

export default {
  name: "ReporteTransaccionesDetalladasPorProveedorOCliente",
  mixins: [ReportesMixin],
  components: {
    ListadoTransaccionesDetalladas,
    vSelect,
  },
  props: {
    servicioBuscarClienteOProvedor: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: {
        desde: formatearFecha(new Date()),
        hasta: formatearFecha(new Date()),
        clienteProveedorId: null,
      },
      clientesProveedores: [],
      retraso: null,
    };
  },
  methods: {
    restablecer() {
      this.rangoFechas = [
        formatearFecha(new Date()),
        formatearFecha(new Date()),
      ];
      this.form.clienteProveedorId = null;
      this.clientesProveedores = [];
      this.items = [];
    },
    buscarClientesProveedores(search, loading) {
      if (search.length) {
        clearTimeout(this.retraso);
        this.retraso = setTimeout(async () => {
          loading(true);
          try {
            const response = await this.servicioBuscarClienteOProvedor({
              q: search,
            });

            this.clientesProveedores = response.data.map((clienteProveedor) => {
              return {
                id: clienteProveedor.id,
                numero_documento_razon_social: `${clienteProveedor.numero_documento} - ${clienteProveedor.razon_social}`,
              };
            });
          } catch (error) {
            console.log(error);
          } finally {
            loading(false);
          }
        }, 350);
      } else {
        this.clientesProveedores = [];
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/reportes.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.text-small {
  font-size: 11px;
}

@media (min-width: 1500px) {
  .text-small {
    font-size: 12px;
  }
}
</style>