<template>
  <ReporteTransaccionesDetalladasPorProveedorOCliente
    titulo="Reportes - Ventas Detalladas por Cliente"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    tipoTransaccion="venta"
    :servicioBuscarClienteOProvedor="servicioBuscarCliente"
  />
</template>

<script>
import ReporteTransaccionesDetalladasPorProveedorOCliente from "../../components/ReporteTransaccionesDetalladasPorProveedorOCliente.vue";
import ReporteVentasService from "../services";
import { ClienteService } from "../../../administracion/cliente_proveedor/services/index";

export default {
  name: "ReporteVentasDetalladasPorClienteLayout",
  components: {
    ReporteTransaccionesDetalladasPorProveedorOCliente,
  },
  methods: {
    servicioGetReporte:
      ReporteVentasService.getReporteVentasDetalladasPorCliente,
    servicioGetReportePdf:
      ReporteVentasService.getReporteVentasDetalladasPorClientePdf,
    servicioBuscarCliente: ClienteService.buscar,
  },
};
</script>